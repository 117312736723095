import "typeface-montserrat"
import React from "react"
import { Global, css } from "@emotion/core"
import Header from "./headerPhoenix"
import TimeBar from "./timeandlocationPhoenix"
import SermonSeriesPhoenix from "./sermonSeriesPhoenix"
import UpcomingEvents from "./eventsPhoenix"
import MeetThePastorPhoenix from "./meetthePastorPhoenix"
import styled from "@emotion/styled"
import Footer from "./footerPhoenix"
import "../../node_modules/react-modal-video/scss/modal-video.scss"

const MainButton = styled.a`
  color: #fff;
  background: #f07525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  width: 300px;
  text-align: center;
  border-radius: 8px;
  text-transform: uppercase;
  text-decoration: none;
  margin: 0 auto;
  margin-bottom: 2rem;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  &:empty {
    display: none;
    margin-bottom: 4rem;
  }
  @media (max-width: 640px) {
    font-size: 1rem;
  }
  @media (max-width: 420px) {
    text-align: center;
  }
`

const Layout = ({
  children,
  sermonSrc,
  notesSrc,
  notesText,
  bibleGroupSrc,
  bibleGroupText,
}) => (
  <>
    <Global
      styles={css`
        * {
          box-sizing: border-box;
          margin: 0;
        }

        html,
        body {
          margin: 0;
          color: #312e2e;
          font-family: -apple-system, BlinkMacSystemFont, "Montserrat",
            "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
          font-size: 14px;
          line-height: 1.4;
          background-color: #f7fafc;

          / *remove margin for the main div that Gatsby mounts into */ > div {
            margin-top: 0;
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
              color: #312e2e;
              line-height: 1.1;

              + * {
                margin-top: 0.5rem;
              }
            }

            strong {
              color: #312e2e;
            }

            li {
              margin-top: 0.25rem;
            }
          }
        }
      `}
    />
    <Header />
    <div
      css={css`
        display: flex;
        min-height: 100%;
        flex-direction: column;
      `}
    >
      <div
        css={css`
          width: 100%;
          margin: 0 auto;
          background-color: black;
          display: flex;
          justify-content: center;
        `}
      >
        <iframe
          src={sermonSrc}
          scrolling="no"
          frameborder="0"
          allowTransparency="true"
          allowFullScreen="true"
          css={css`
            height: 50vw;
            width: 80%;
            padding: 6rem;
            background-color: black;
            @media (max-width: 1050px) {
              padding: 4rem 1rem 1rem 1rem;
              height: 60vw;
            }
            @media (max-width: 640px) {
              width: 100%;
              margin-top: 1rem;
            }
          `}
        ></iframe>
      </div>
      <TimeBar />
      <main
        css={css`
          margin: 2rem auto 4rem;
          max-width: 90vw;
          width: 1050px;
          flex-grow: 1;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-items: center;
          `}
        >
          <MainButton href={notesSrc} target="_blank">
            {notesText}
          </MainButton>
          <MainButton
            href={bibleGroupSrc}
            css={css`
              margin-bottom: 4rem;
            `}
            target="_blank"
          >
            {bibleGroupText}
          </MainButton>
        </div>
        {children}
        <SermonSeriesPhoenix />
        <UpcomingEvents />
        <MeetThePastorPhoenix />
      </main>
    </div>
    <Footer />
  </>
)

export default Layout
